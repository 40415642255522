import consumer from "./consumer"

window.commentsChannelInit = function(requestId) {
  return consumer.subscriptions.create({
    channel: "CommentsChannel",
    id: requestId
  }, {
    connected() {
      console.log("Connected to comments!");
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      $('#commentsList.request_'+data.content.request_id).append(data.html);
      $('#comment-text').val('');
      $('#publish-and-complete').addClass('d-none');
      // highlight added comment
      $('.comment').last().addClass('alert alert-warning');
      setTimeout(function () {
        $('.comment.alert.alert-warning').removeClass('alert alert-warning');
      }, 1500);
      // show buttons
      $('#complete-request-btn').removeClass('d-none');
      $('#cancel-request-btn').addClass('d-none');
      // Called when there's incoming data on the websocket for this channel
    }
  });
}
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// added to fix $ not a function error for Notifications Clear All button
import jquery from 'jquery';
window.$ = window.jquery = jquery;

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

require('bootstrap')
require('magnific-popup')
require('jquery-slimscroll')
require('timeago')
require('node-waves')
require('datatables.net')
require('datatables.net-bs4')
require('datatables.net-responsive-bs4')
require('datatables.net-buttons/js/buttons.print')
require('markerjs')
require('jquery-validation')
require('twitter-bootstrap-wizard')
require('bootstrap-select')
require('ion-rangeslider')
require('packs/requests')
require('packs/theme')

require('packs/marker_config')
require('packs/trix_config')
require('packs/drag_and_drop_config')
require('packs/form_wizard_config')
require('packs/forms_validations')
require('packs/stripe')
require('packs/coupon')
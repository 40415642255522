import { MarkerArea } from "markerjs";

$(document).on('turbolinks:load', function() {
    var markerInstance = null;
    var markerToolbarClose = true;
    var galleryOpen = false;

    var setOpenImageAttributes = function (imageEl, onImageLoadCallback){
        var currentAttributesList = imageEl.getAttributeNames();
        var currentAttributesValuesList = [];

        currentAttributesList.map(function(attrName, index) {
            currentAttributesValuesList.push({
                name: attrName,
                value: imageEl.getAttribute(attrName),
            });
        });
        currentAttributesList.map(function(attrName, index) {
            imageEl.removeAttribute(attrName);
        });
        imageEl.setAttribute('crossorigin', 'anonymous');
        currentAttributesValuesList.map(function(attrData, index) {
            imageEl.setAttribute(attrData.name, attrData.value);
        });

        $(imageEl).on('load', function (e){
            onImageLoadCallback();
        })
    }

    var initMarkerInstance = function (params) {
        var popupImage = params.popupImage;
        var fileId = params.fileId;
        var savedState = params.state;
        var galleryIns = params.galleryIns;
        var galleryItemImage = params.galleryItemImage;

        // $(popupImage).attr('crossorigin', 'anonymous');

        var parsedSavedState = { markers: [] };
        if (savedState) {
            parsedSavedState = savedState;
            if (typeof savedState === 'string') {
                try {
                    parsedSavedState = JSON.parse(savedState);
                } catch (e) {
                    console.log(e)
                }
            }
        }

        markerInstance = new MarkerArea(popupImage, {
            renderAtNaturalSize: true,
            previousState: parsedSavedState,
        });

        markerInstance.show((dataUrl, state) => {
            var stringState = '{}';
            var spinner = $('.annotation-apply-status');

            spinner.removeClass('d-none');

            try {
                stringState = JSON.stringify(state);
            } catch (e) {
                console.log(e)
            }

            // prepare form data object
            var formData = new FormData();
            formData.append('active_storage_attachment_id', fileId);
            formData.append('markup', stringState);
            formData.append('marked_image', dataUrl);

            $.ajax({
                method: 'POST',
                url: '/expert/annotations',
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
            }).done(function(resp){
                // console.log('Markup added - ', resp)
                galleryIns.close();
                galleryOpen = false;
                // galleryItemImage.attr('data-markup', JSON.stringify(state))
                galleryItemImage.attr('src', resp.img_path)
                galleryItemImage.parent('a').attr('href', resp.img_path)
                spinner.addClass('d-none');

            }).fail(function(e){
                spinner.addClass('d-none');
                console.log('request error - ', e)
            });

        }, () => {
            if (markerToolbarClose) {
                galleryIns.close();
                galleryOpen = false;
            }
        });

        setTimeout(() => {
            var styles = {
                'z-index': 1044,
                cursor: "auto"
            };
            $(markerInstance.markerImage.parentNode).css(styles);
        })
    }

    $.fn.magnificPopup && $('.gallery_container').magnificPopup({
        delegate: 'a.image-popup',
        gallery:{
            enabled: true,
            tCounter: ''
        },
        // type: 'inline',
        // inline: {
        //     markup: `<div class="mfp-figure custom-markup">
        //         <button title="Close (Esc)" type="button" class="mfp-close">×</button>
        //         <figure>
        //             <img crossorigin="anonymous" class="mfp-img" alt="undefined" src="">
        //             <figcaption>
        //                 <div class="mfp-bottom-bar">
        //                     <div class="mfp-title">
        //                     </div>
        //                     <div class="mfp-counter">
        //                     </div>
        //                 </div>
        //             </figcaption>
        //         </figure>
        //     </div>`,
        // },
        callbacks: {
            close: function () {
                if (markerInstance) {
                    try {
                        markerInstance.close();
                    } catch (e) {}
                }
                galleryOpen = false;
                $('.mfp-custom-control.prev-btn').remove();
                $('.mfp-custom-control.next-btn').remove();
                $('body').removeClass('mfp-custom-nav-active');
            },
            open: function(){

                if (this.items.length < 2) return;

                var galleryInst = this;
                var customPrevBtn = $('<a class="mfp-custom-control prev-btn"><span></span></a>');
                var customNextBtn = $('<a class="mfp-custom-control next-btn"><span></span></a>');

                $('body').prepend(customPrevBtn).prepend(customNextBtn);
                $('body').addClass('mfp-custom-nav-active');

                customPrevBtn.click(function(e){
                    e.preventDefault();
                    galleryInst.prev();
                });
                customNextBtn.click(function(e){
                    e.preventDefault();
                    galleryInst.next();
                });
            },
            imageLoadComplete: function (){
                var galleryInstance = this;
                var galleryItemImage = null;
                galleryItemImage = $(this.currItem.el).find('img');
                var markerInitFunctionality = function(){
                    $(document).delegate(".markerjs-text-editor", "click", function(event){
                        var popupBody = $('.mfp-wrap.mfp-gallery');
                        var editorControls = $(this).find('.markerjs-text-editor-button');
                        var editorTextArea = $(this).find('textarea');
                        popupBody.css('display', 'none');
                        editorTextArea.focus();

                        editorControls.click(function(event) {
                            event.stopPropagation();
                            event.preventDefault();
                            popupBody.css('display', 'block');
                        })
                    });

                    if (galleryOpen && markerInstance) {
                        markerToolbarClose = false;
                        markerInstance.cancel();
                    }
                    setTimeout(() => {
                        markerToolbarClose = true;
                        initMarkerInstance({
                            popupImage: galleryInstance.currItem.img[0],
                            fileId: galleryItemImage.attr('id'),
                            state: $(galleryInstance.currItem.el).find('img').data('markup'),
                            galleryIns: galleryInstance,
                            galleryItemImage: galleryItemImage,
                        });
                    }, 500);
                    galleryOpen = true;
                }
                if (galleryItemImage.hasClass('marker-init')) {
                    setOpenImageAttributes(this.currItem.img[0], markerInitFunctionality);
                } 

            },
            // markupParse: function(template, values, item) {
            //     console.log('template - ', template)
            //     console.log('values - ', values)
            //     console.log('item - ', item)
            // }
        }
    });

    $('.gallery_container_comment').map(function(index, item) {
        $.fn.magnificPopup && $(item).magnificPopup({
            delegate: 'a.image-popup',
            gallery:{
                enabled: true
            },
        });
    });
});
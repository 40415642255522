$(document).on('turbolinks:load', function(){
    var stripe = Stripe(process.env.STRIPE_PUBLISHABLE_KEY);
    var elements = stripe.elements();
    // we need second instance of stripe elements
    var elements2 = stripe.elements();

    // Create an instance of the card Element.
    var card = elements.create('card');
    var card_for_update = elements2.create('card');

    // Add an instance of the card Element into the `card-element` <div>.
    if( $('#card-element').length ) {
        card.mount('#card-element');
        card.on('change', errorHandler);
        var form = document.getElementById('form-payment');

        form.addEventListener('submit', function(event) {
            submitHandler(event, card);
        });
    }

    if( $('#other-card-element').length ) {
        card_for_update.mount('#other-card-element');
        card_for_update.on('change', errorHandler);
        var card_update_form = document.getElementById('form-card');

        card_update_form.addEventListener('submit', function(event) {
            submitHandler(event, card_for_update);
        });
    }

    function errorHandler(event){
        $('#js--stripe-form-submit').prop('disabled', false);

        if (event.error) {
            $('#js--stripe-form-submit').prop('disabled', true);
        }
    }

    function submitHandler(event, current_card) {
        event.preventDefault();

        stripe.createToken(current_card).then(function(result) {
            if (result.error) {
                // Inform the customer that there was an error.
                var errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
            } else {
                // Send the token to your server.
                console.log(event);
                stripeTokenHandler(result.token, event.target);
            }
        });
    }

    function stripeTokenHandler(token, form) {
        // Insert the token ID into the form so it gets submitted to the server
        // var form = document.getElementById('form-payment');
        var hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'stripeToken');
        hiddenInput.setAttribute('value', token.id);
        form.appendChild(hiddenInput);

        // Submit the form
        form.submit();
    }

}).on('turbolinks:before-cache', function(){
    //$('#card-element').remove()
});
$(document).on('turbolinks:load', function() {
    var $validator = $("#form-payment").validate({
        rules: {
            "subscription[subscription_plan_id]": {
                required: true
            },
            "billing_info[cardholder_name]": {
                required: true
            },
            "billing_info[street]": {
                required: true
            },
            "billing_info[city]": {
                required: true
            },
            "billing_info[state]": {
                required: true
            },
            "billing_info[postal_code]": {
                required: true
            },
            "billing_info[country]": {
                required: true
            }
        }
    });

    $("#progressbarwizard").bootstrapWizard({
        onTabShow: function(tab, navigation, index) {
            switch(index) {
                case 1:
                    $('.check-sign').addClass('d-block');
                    $('.check-num').addClass('d-none');
                    break;
                case 2:
                    $('.check-sign-2').addClass('d-block');
                    $('.check-num-second').addClass('d-none');
                    break;
            }
        },
        onNext: function(tab, navigation, index) {
            var $valid = $("#form-payment").valid();
            if (!$valid) {
                $validator.focusInvalid();
                return false;
            }
        },
        onTabClick: function(activeTab, navigation, currentIndex, nextIndex) {
            if (nextIndex <= currentIndex) {
                return;
            }
            var $valid = $("#form-payment").valid();
            if (!$valid) {
                $validator.focusInvalid();
                return false;
            }
            if (nextIndex > currentIndex+1){
                return false;
            }
        }
    });

    var $sign_up_validator = $("#new_user").validate({
        rules: {
            "user[email]": {
                required: true
            },
            "user[password]": {
                required: true,
                minlength: 6
            },
            "user[password_confirmation]": {
                required: true,
                minlength: 6
            },
            "user[first_name]": {
                required: true
            },
            "user[last_name]": {
                required: true
            },
            "user[organization_attributes][name]": {
                required: true
            },
            "user[organization_attributes][size]": {
                required: true
            },
            "user[position]": {
                required: true
            },
            "user[tos_agreement]": {
                required: true
            }
        }
    });

    $("#sign-up-wizard").bootstrapWizard({
        onTabShow: function(tab, navigation, index) {
            var o = (index + 1) / navigation.find("li").length * 100;
            $("#sign-up-wizard").find(".bar").css({
                width: o + "%"
            });
        },
        onNext: function(tab, navigation, index) {
            var $valid = $("#new_user").valid();
            if (!$valid) {
                $sign_up_validator.focusInvalid();
                return false;
            }
        },
        onTabClick: function(activeTab, navigation, currentIndex, nextIndex) {
            if (nextIndex <= currentIndex) {
                return;
            }
            var $valid = $("#new_user").valid();
            if (!$valid) {
                $sign_up_validator.focusInvalid();
                return false;
            }
            if (nextIndex > currentIndex+1){
                return false;
            }
        }
    });
});

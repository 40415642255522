$(document).on('turbolinks:load', function(){
    $('#validate-coupon').click(function(event) {
        $.ajax({
            method: 'POST',
            url: '/validate_coupon',
            data: {
                'coupon': $('#promo-code').val(),
                'plan_id': $('input[name="subscription[subscription_plan_id]"]:checked').val()
            }
        }).done(function(resp){
            let coupon_label = $('.coupon-selected');

            coupon_label.removeClass('d-none').addClass('show').html(
                `${resp.name} <button type="button" class="remove-coupon close d-inline"> ×</button>`
            );

            if(!$('.coupon-description').length) {
                coupon_label.after(`<div class="coupon-description">${resp.description}</div>`);
            }

            $('.coupon-error').text('').hide();
            $('.coupon-final-page').removeClass('d-none');
            $('#promo-code').removeClass('error');
            $('#rw-next-bill').text(resp.next_bill);
            $('#rw-full-price').text(`$${resp.due_today}`);
        }).fail(function(e){
            $('#promo-code').addClass('error');
            $('.coupon-selected').removeClass('show').addClass('d-none').html();
            $('.coupon-final-page').addClass('d-none');
            $('.coupon-description').remove();
            $('.coupon-error').show().text(e.responseJSON.message);
        });
    });
});

$(document).on('click', '.remove-coupon', function(e) {
    $('.coupon-selected').removeClass('show').addClass('d-none').html();
    $('.coupon-final-page').addClass('d-none');
    $('.coupon-description').remove();
    $('#promo-code').val('');
});

$(document).on('click', '.pricing-plan-item', function(e) {
    console.log('Radio changed')
    if($('#promo-code').val()) {
        $('#validate-coupon').click();
    }
});

import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to the notifications!");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // update counter
    $('.noti-icon-badge').html(parseInt($('.noti-icon-badge').html())+1).removeClass('d-none');
    // add new notification
    $('#notifications').prepend(data.html);
    // slimscroll sheet
    $('.slimscroll').slimScroll({
      height: 'auto',
      position: 'right',
      size: "8px",
      touchScrollStep: 20,
      color: '#9ea5ab'
    });
    $('time.timeago').timeago();
  }
});

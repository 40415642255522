import consumer from "./consumer"

consumer.subscriptions.create("RequestLockChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to the request lock!");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.content.redirect_to){
      window.location.replace(data.content.redirect_to);
    } else {
      $('#ajax-dynamic-modal').html(data.html);
      $('#confirm-work-on-request').modal('show');
    }
  }
});

$(document).on('turbolinks:load', function() {
    $('#form-card').validate({
        rules: {
            "subscription[subscription_plan_id]": {
                required: true
            },
            "billing_info[cardholder_name]": {
                required: true
            },
            "billing_info[street]": {
                required: true
            },
            "billing_info[city]": {
                required: true
            },
            "billing_info[state]": {
                required: true
            },
            "billing_info[postal_code]": {
                required: true
            },
            "billing_info[country]": {
                required: true
            }
        }
    });

    $('#invite_new_user').validate({
        rules: {
            "user[email]": {
                required: true
            },
            "user[first_name]": {
                required: true
            },
            "user[last_name]": {
                required: true
            }
        }
    });

    $('#requestForm').validate({
        rules: {
            "request[content]": {
                required: true
            },
            "request[files][]": {
                required: true
            }
        }
    });

    $('#new_comment').validate({
        rules: {
            "comment[content]": {
                required: true
            },
        }
    });

    $('.edit_user').validate({
        rules: {
            "user[first_name]": {
                required: true
            },
            "user[last_name]": {
                required: true
            }
        }
    });

    $('.update_password').validate({
        rules: {
            "user[password]": {
                minlength: 6
            },
            "user[password_confirmation]": {
                minlength: 6
            },
            "user[current_password]": {
                minlength: 6
            },
        }
    });

    $('.setup-account').validate({
        rules: {
            "user[password]": {
                required: true,
                minlength: 6
            },
            "user[password_confirmation]": {
                required: true,
                minlength: 6
            },
            "user[tos_agreement]": {
                required: true
            }
        }
    });

});
// TODO split all js by controllers. Rewrite in common style!
$(document).on('turbolinks:load', function() {
  var updatePathologyOptions = function(selectorId, options) {
    $(selectorId).html(options);
    var unsorted_options = $(`${selectorId} option`);
    var sorted_options = unsorted_options.sort(function(a,b) {
      if (a.text > b.text) return 1;
      if (a.text < b.text) return -1;
      return 0
    });
    var pathology_list = $(selectorId).empty().append( sorted_options );
    pathology_list.find('option:eq(0)').prop('selected', true);
  };

  var updateImgLabelOptions = function(selectorId, options) {
    $(selectorId).html(options);
    var unsorted_options = $(`${selectorId} option`);
    var sorted_options = unsorted_options.sort(function(a,b) {
      if (a.text > b.text) return 1;
      if (a.text < b.text) return -1;
      return 0
    });
    var list = $(selectorId).empty().append( sorted_options );
    list.find('option:eq(0)').prop('selected', true);
  };

  var registerPathBasedOnExam = function() {
    // Exam type
    var pathologies = $('#request_pathology_id').html();
    var imageLabels = $('#request_attachment_image_label_id').html();

    // var image_labels = $('#request_image_label_id').html();
    $('#request_exam_type_id').on('change', function() {
      var exam_type, options, imgLabelOptions;
      exam_type = $('#request_exam_type_id :selected').text();
      options = $(pathologies).filter(`optgroup[label='${exam_type}']`).html();
      imgLabelOptions = $(imageLabels).filter(`optgroup[label='${exam_type}']`).html();

      if (options) {
        updatePathologyOptions('#request_pathology_id', options);
        updatePathologyOptions('#request_attachment_pathology_id', options);
      }

      if(imgLabelOptions) {
        updateImgLabelOptions('#request_attachment_image_label_id', imgLabelOptions);
      }

      // var labelImageOptions;
      // labelImageOptions = $(image_labels).filter(`optgroup[label='${exam_type}']`).html();

      // if (labelImageOptions) {
      //   $('#request_image_label_id').html(options);
      //   var unsorted_options = $('#request_image_label_id option');
      //   var sorted_options = unsorted_options.sort(function(a,b) {
      //     if (a.text > b.text) return 1;
      //     if (a.text < b.text) return -1;
      //     return 0
      //   });
      //   var image_label_list = $("#request_image_label_id").empty().append( sorted_options );
      //   image_label_list.find('option:eq(0)').prop('selected', true);
      // }
    });
    $('#request_exam_type_id').trigger('change');
  }

  //dependent dropdowns
  jQuery(function() {
    // Specialty
    var exam_types = $('#request_exam_type_id').html();
    $('#request_specialty_id').on('change', function() {
      var specialty, options;
      specialty = $('#request_specialty_id :selected').text();
      options = $(exam_types).filter(`optgroup[label='${specialty}']`).html();

      if (options) {
        $('#request_exam_type_id').html(options);
        var unsorted_options = $('#request_exam_type_id option');
        var sorted_options = unsorted_options.sort(function(a,b) {
          if (a.text > b.text) return 1;
          if (a.text < b.text) return -1;
          return 0
        });
        var list = $("#request_exam_type_id").empty().append( sorted_options );
        list.find('option:eq(0)').prop('selected', true);
        
        registerPathBasedOnExam();
      } else {
        registerPathBasedOnExam();
      }
    });
    $('#request_specialty_id').trigger('change');

    $('#request_pathology_id').on('change', function() {
      var selectedPathology = $('#request_pathology_id :selected').val();
      $('#request_attachment_pathology_id').val(selectedPathology);
    });
  });

  // submit form on page limit select
  $(function() {
    $('#pagination-limit').change(function() {
      this.form.submit();
    });
  });

  // select/deselect all checkboxes on settings page
  $( function() {
    $('#types-selector').click(function() {
      var checked = $(this).prop('checked');
      $('.exam-types-list').find('input:checkbox').prop('checked', checked);
    });

    // Handle indeterminate state for parent checkbox
    var examTypeOptionsList = $('.exam-types-list input');

    if(examTypeOptionsList.length > 0) {
      var changeParentInput = function(val){
        var statesList = [];
        var parentCheckboxEl = $('#types-selector').get(0);
        examTypeOptionsList.map(function (index, item) {
          statesList.push($(item).prop('checked') ? 1 : 0);
        })
        statesList = statesList.filter(function (x, i, a) {
          return a.indexOf(x) === i;
        });
        if (statesList.length > 1 && parentCheckboxEl) {
          parentCheckboxEl.indeterminate = true;
          parentCheckboxEl.checked = false;
        } else {
          parentCheckboxEl.indeterminate = false;
        }
      }

      changeParentInput();

      examTypeOptionsList.change(function (e) {
        changeParentInput();
      });
    }
  });

  // request comments via cable.
  // not sure it looks good
  var requestId = document.querySelector('meta[name="request-id"]')?.content
  if (requestId) {
    if (window.commentsChannel && window.commentsChannel.identifier == '{"channel":"CommentsChannel","id":"'+requestId+'"}'){
      // do nothing
    } else {
      window.commentsChannel = window.commentsChannelInit(requestId);
    }
  } else if (window.commentsChannel) {
    window.commentsChannel.unsubscribe();
    window.commentsChannel = null;
  }
  $("#new_comment").bind("ajax:complete", function(event,xhr,status){
    $('#comment_message').val('');
  })

  $.fn.timeago && $('time.timeago').timeago();

  // submit request modal
  $('.submit-request.unconfirmed').click(function(event) {
    // it calls twice.
    if($('.submit-request').hasClass('unconfirmed')) {
      // Small tmp hack. latter lets change it with custom validations
      if (document.getElementById('request_content').checkValidity() &&
        document.getElementById('request_files').checkValidity()) {
        event.preventDefault();
        $('#confirm-submit').modal('show');

        $('#confirm-submit-btn').click(function(){
          $('#confirm-submit').modal('hide');
          $('.submit-request').removeClass('unconfirmed');
          // trigger submit event
          var event = new Event('submit', {
            'bubbles': true, 'cancelable': true});
          document.getElementById('requestForm').dispatchEvent(event);
        });
      }
    }
  });

  // submit comment modal
  $('.complete-comment').click(function(event) {
    $('.com').click(function(){
      console.log('THIS', this)
      $('#confirmCommentComplete').modal('hide');
      var form = document.getElementById('new_comment');
      $('<input>').attr({type: 'hidden', name: 'complete_request', value: this.value}).appendTo(form);
      form.submit();
    });
  });

  //initialize datatables
  if ($('#datatable').length) {
    if ($.fn.dataTable.isDataTable( '#datatable' )) {
      $('#datatable').DataTable();
    } else {
      var requestsTable = $("#datatable").DataTable({
        "paging": false,
        "searching": false,
        "info": false,
        "aaSorting": [],
        "columnDefs": [
          {responsivePriority: 1, targets: 1},
          {responsivePriority: 2, targets: 4}
        ],
        "responsive": true,
        "autoWidth": true
      });
      new $.fn.DataTable.Buttons(requestsTable, {
        buttons: [
          {
            text: '<i class="fas fa-print"></i>',
            className: 'btn btn-info waves-effect waves-light',
            action: function ( e, dt, node, config ) {

              const currentUrl = window.location.href;

              // Separate the base URL (up to the path, excluding the query string) and the query string
              const baseUrl = currentUrl.split('?')[0];
              const queryString = currentUrl.split('?')[1] || '';

              // Assemble the new URL
              const newUrl = `${baseUrl}/export.pdf${queryString ? '?' + queryString : ''}`;

              var req = new XMLHttpRequest();
              req.open("GET", newUrl, true);
              req.responseType = "blob";

              req.onload = function (event) {
                var blob = req.response;
                console.log(blob.size);
                var link=document.createElement('a');
                link.href=window.URL.createObjectURL(blob);
                link.download="report.pdf";
                link.click();
              };
              req.send();
            }
          }
        ]
      });
      if ($('.fa-print').length == 0) {
        requestsTable.buttons().container().insertBefore('#pagination-limit-form');
      }
    }
  }

  // show publish & complete btn depending on text
  $('#comment-text').on('keydown keyup change', function(){
    var $submit = $('#publish-and-complete');
    var $complete = $('#complete-request-btn');

    if($(this).val() == ''){
      if($('.comment').length > 0) {
        $complete.removeClass('d-none');
      }
      $submit.addClass('d-none');
    }else{
      $submit.removeClass('d-none');
      $complete.addClass('d-none');
    }
  }).trigger('change');

  //generate complex groups select
  // $('#inviteUser, .edit-team-member').on('shown.bs.modal', function (e) {
  $(document).on('show.bs.modal','#inviteUser, .edit-team-member', function () {
    var $select = $(this).find('select.dynamic-select-group');

    $.ajax({
      method: 'GET',
      url: '/subscriber/groups',
    }).done(function(resp){
      var selected = $select.val();
      var listitems = '<option value="">Select or add group</option>';

      $select.find('option').remove();

      $.each(resp, function(key, value){
        listitems += '<option value=' + value.id + '>' + value.name + '</option>';
      });
      $select.append(listitems);

      // Add + item to Groups select
      $select.append($('<option>', {
        value: '#editGroups',
        text: '+ Add/Edit Group'
      }));

      // Make option selected in Edit popup select
      if($.isNumeric(selected)){
        $select.val(selected);
      }

      // Lib for styling
      $select.selectpicker('refresh');
    }).fail(function(e){
      console.log('Request error - ', e);
    });
  });

  $('.roles-select').selectpicker({
    width: '100%',
  });

  // handle open Edit Groups popup
  $('#inviteUser .dynamic-select-group').change(function(){
    if($(this).val() === '#editGroups'){
      $('#editGroups').modal('show');
      $('#inviteUser').modal('hide');
      localStorage.setItem('popup', '#inviteUser');
      $('.dynamic-select-group').val($("#target option:first").val());
    }
  });

  $('.edit-team-member .dynamic-select-group').change(function(){
    if($(this).val() === '#editGroups'){
      $('#editGroups').modal('show');
      $(".edit-team-member").modal("hide");
      localStorage.setItem('popup', `#editTeamMember_${$(this).data("id")}`);
      $('.dynamic-select-group').val($("#target option:first").val());
    }
  });

  // redirect to correct popup after cancel
  $('#cancel-groups-popup-btn').click(function(){
    $('#editGroups').modal('hide');
    $(localStorage.getItem('popup')).modal('show');
  });

  // open correct popup when bulk update is done
  // not best solution but will refresh groups select options
  // can be refactored later
  $('.alert').ready(function() {
    if ($('.alert:contains("Organization groups were successfully updated.")').length > 0) {
      $(localStorage.getItem('popup')).modal('show');
    }
  });

  // refresh groups popup
  $(document).on('show.bs.modal','#editGroups, .edit-team-member', function () {
    $.ajax({
      method: 'GET',
      url: '/subscriber/groups',
    }).done(function (resp) {
      $('#groups-list').empty();

      var items = [];
      $.each(resp, function(key, value){
        items += `
          <div class="form-group mb-3" data-org-group-id="${value.id}">
            <input class="form-control edit-group-name" type="text" value="${value.name}" name="organization[groups_attributes][${key}][name]" id="organization_groups_attributes_${key}_name">
            <input type="hidden" value="false" name="organization[groups_attributes][${key}][_destroy]" id="organization_groups_attributes_${key}__destroy">
            <a type="button" class="group-del-btn" href="#"><i class="fas fa-trash"></i></a>
          </div>
          <input type="hidden" value="${value.id}" name="organization[groups_attributes][${key}][id]" id="organization_groups_attributes_${key}_id">
        `;
      });
      $('#groups-list').append(items);
    });
  });

  function add_empty_field(event) {
    if(event != null) {
      event.preventDefault();
    }
    var index = $('#bulk-edit-groups input.form-control').length + 1;
    var block = `
      <div class="form-group mb-3">
        <input class="form-control edit-group-name" id="organization_group__name" placeholder="Group name" name="organization[groups_attributes][${index}][name]" type="text">
      </div>
    `
    $("#fields-list").append(block);
  }

  // clean dynamically added fields from the form
  // useful when user reopen modal without refreshing the page
  $(document).on('hide.bs.modal','#editGroups, .edit-team-member', function () {
    $('#fields-list').empty();
    add_empty_field(null);
  });

  // add new fields to the form
  $('#add-new-group-field').click(function(e) {
    add_empty_field(e);
  });

  $(document).on('click', '.group-del-btn', function(e) {
    e.preventDefault();
    $(this).prev('input').val(true);
    $(this).closest('.form-group').hide();
  });

  $(document).on("ajax:success", "#bulk-edit-groups", function(data){
    $('#editGroups').modal('hide');
    $(localStorage.getItem('popup')).modal('show');
  });

  // style selected element on pricing plans popup
  $('.pricing-plan-label').click(function(){
    var label = $('.pricing-plan-label');
    label.find('input:radio').removeAttr('checked');
    label.removeClass('plan-checked');

    $(this).addClass('plan-checked');
    $(this).find('input:radio').attr('checked', 'checked');
  });

  //custom pricing plan range slider
  function initRangeSlider(){
    var slidersList = [];
    $(".js-range-slider").map(function(){
      var __that = this;
      var parentId = $(this).closest('.plans-wrapper').attr('id');
      var childSliderClass = 'child-slider-'+ parentId;
      $(this).addClass(childSliderClass);
      $(this).attr('data-parent-id', parentId);
      slidersList.push(childSliderClass);

      $(this).ionRangeSlider({
        min: 50,
        max: 500,
        step: 25,
        skin: 'big',
        onChange: function(data) {
          $(".js-range-slider").map(function(index, item){
            if (!$(item).hasClass(childSliderClass)) {
              $(this).data('ionRangeSlider').update({from: data.from});
              set_custom_plan_value(data.from, $(this).attr('data-parent-id'));
              $(this).val(data.from);
              $(this).attr('value', data.from);
              $(this).change();
            }
          });
          $(__that).val(data.from);
          $(__that).attr('value', data.from);
          $(__that).change();
          set_custom_plan_value(data.from, parentId);
        },
      })
    })
  }

  initRangeSlider();

  //set base value for both sliders of Custom plan
  set_custom_plan_value(50,'monthly-plans-section');
  set_custom_plan_value(50,'yearly-plans-section');

  function set_custom_plan_value(requests_num, parentId = 'monthly-plans-section'){
    var form_requests_num = $('#' + parentId +' .custom-plan-range').val();
    var item = $('#' + parentId +' .custom-plan-radio-btn');
    var item_price = item.data('item-price');
    var multiplicator = requests_num === 0 ? form_requests_num : requests_num

    $('#' + parentId +' .custom-plan-price').html((parseFloat(item_price) * multiplicator).toFixed(0));
    $('#' + parentId +' .req-num').html(multiplicator + ' exams per month');

    item.data('requests', multiplicator);
    item.click();
  }

  //switch between monthly and yearly plans
  $('#billing_period').change(function(){
    if (!$(this).is(':checked')) {
      $('#monthly-plans-section').addClass('d-none')
      $('#yearly-plans-section').removeClass('d-none')
    } else {
      $('#monthly-plans-section').removeClass('d-none')
      $('#yearly-plans-section').addClass('d-none')
    }
  })

  // update subscriptions popup preview tab
  $(document).on('click', 'input[name="subscription[subscription_plan_id]"]:radio', function() {
    // this all only for preview

    if($(this).data('title') === 'CUSTOM'){
      preview_price = (parseFloat($(this).data('full-price')) * parseInt($('.custom-plan-range').val())).toFixed(0);
      full_price = $(this).data('period') === 'Year' ? preview_price * 12 : preview_price;
      $('#rw-price').html(preview_price);
      $('#rw-full-price').html('$' + full_price);
    } else {
      $('#rw-price').html($(this).data('price'));
      $('#rw-full-price').html('$' + $(this).data('full-price'));
    }

    $('#rw-period').html($(this).data('period') === 'Month' ? 'Month to Month' : 'Annually');
    $('#rw-interval').html($(this).data('period') === 'Month' ? 'Billed Monthly' : 'Billed Annually');
    $('#rw-users').html($(this).data('users'));
    $('#rw-requests').html($(this).data('requests'));
    $('#rw-title').html($(this).data('title'));
    $('#rw-next-bill').html($(this).data('next-bill'));
  });

  $('.card-box.widget-user.clickable').click(function(){
    const statusValue = $(this).data('status');
    console.log('clicking - statusValue: ', statusValue);

    if(statusValue) {
      const url = new URL(window.location.href);
      url.searchParams.set('status', statusValue);
      window.location.href = url.toString();
    }
  });
});

/* direct_uploads.js */
addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `)
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})
